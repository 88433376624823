<template>
  <div>
    <Modal
      :value="true"
      :title="(formData.id?'编辑':'新增')+'课件'"
      :closable="false"
      :mask-closable="false"
    >
      <Form :label-width="50" style="padding-right:2em">
        <FormItem label="标题">
          <Input placeholder="请输入标题" v-model="formData.title" />
        </FormItem>
        <FormItem label="序号">
          <InputNumber placeholder="请输入整数" v-model="formData.sort" style="width:8em" />
        </FormItem>
      </Form>
      <template slot="footer">
        <Button type="text" @click="$emit('cancel')">取消</Button>
        <Button type="primary" @click="submitFn">确认</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { create, modify } from './coursewareService';
export default {
  props: {
    formData: {
      required: true
    },
    value: {}
  },
  methods: {
    submitFn() {
      if (!this.formData.title || !this.formData.title.trim()) {
        this.$Message.warning('请输入标题！');
        return;
      }
      if (this.formData.sort !== 0 && !this.formData.sort) {
        this.$Message.warning('请输入序号！');
        return;
      }
      let fn = this.formData.id ? modify : create;
      fn(this.formData).then((res) => {        
        this.$emit('finished');
      }).catch(err=>{
        this.$Message.warning(err)
      });
    }
  }
};
</script>

<style>
</style>