<template>
  <div>
    <CategoryForm @ok="onFormOk" v-if="editing && editable && item.code != 0" :value="item.name" @colse="editing = false"
      class="item" />

    <Button v-else :type="active ? 'primary' : 'default'" size="small" :ghost="active" class="item"
      :class="editable ? 'show-edit' : ''">
      <span @click="$emit('select')">{{ item.name }}</span>
      <span class="opt">
        <Icon type="md-create" @click="editing = true" />
        <Poptip confirm title="确定删除吗?" @on-ok="$emit('del')" transfer :disabled="!delAble">
          <Icon type="md-trash" :class="delAble ? '' : 'text-mute'" />
        </Poptip>
      </span>
    </Button>

    <CategoryModal v-if="item.code == 0" :item="item" :modelShow="editing" @ok="modalOk" @close="editing = false" />

  </div>

</template>

<script>
// 删除，（有底层直接屏蔽按钮）（主题下有课程无法删除，后台校验）
// 编辑要引用同步，
// 选择要同步（清理后续选择，记录为最后选择 last -- group的当前层和下一层新增，谁能编辑）
import CategoryForm from './CategoryForm';
import CategoryModal from './CategoryModal';
import { modifyCategory } from './categoryService';
export default {
  components: { CategoryForm, CategoryModal },
  props: {
    editable: {
      default: false
    },
    item: {
      required: true
    },
    allList: {
      type: Array
    },
    active: {}
  },
  data() {
    return {
      editing: false
    };
  },
  computed: {
    delAble() {
      return this.allList.every((ele) => ele.parentId !== this.item.id);
    }
  },

  methods: {
    modalOk(name, orgId) {
      this.editing = false;

      let updateData = { ... this.item }

      let update = true
      if (updateData.name != name) {
        updateData.name = name;
        update = false
      } else {
        delete updateData["name"];
      }

      if (updateData.orgId != orgId) {
        updateData.orgId = orgId;
        update = false
      }

      if (update) {
        console.log("不修改！")
        return
      }

      const that = this
      modifyCategory(updateData).then(res => {
        that.item.name = name;
        that.item.orgId = orgId;
      }).catch(err => {
        that.$Message.warning(err);
      });

    },
    onFormOk(name) {
      this.editing = false;
      if (!name || !name.trim()) {
        return;
      }
      name = name.trim();
      if (this.item.name === name) {
        return;
      }
      let buf = this.item.name;
      this.item.name = name;
      modifyCategory(this.item).catch(err => {
        this.$Message.warning(err);
        this.item.name = buf;
      });
    }
  },
  mounted() { }
};
</script>

<style scoped>
.item {
  display: inline-block;
  white-space: nowrap;
  margin: 0 0.5em;
}

.item:hover,
.item.active {
  color: #2d8cf0;
}

.opt {
  display: none;
}

.show-edit .opt {
  display: inline-block;
}
</style>