<template>
  <div>
    <ListFrame
      :getPageFn="getPageFn"
      :immediateInit="false"
      :customedParams="customedParams"
      :viewConf="{showReorder:false}"
    >
      <template v-slot:list="{list,requestFn}">
        <Table :columns="columns" :data="list" style="margin-top:20px">
          <template v-slot:shelf="{row}">
            <i-switch
              v-if="roleCode===6"
              size="small"
              :value="row.status===1"
              @on-change="changeShelf($event,row)"
            ></i-switch>
            <div v-else>
              <span v-if="row.status===1" class="text-success">已上架</span>
              <span v-else class="text-mute">已下架</span>
            </div>
          </template>

          <template v-slot:courseware="{row,index}">
            <FileManage
              :maxLen="1"
              :files="row.courseKeys"
              type="pdf"
              @change="onFilesChange(row,'courseKeys',list[index])"
            />
          </template>
          <template v-slot:audio="{row,index}">
            <FileManage
              :files="row.audioKeys"
              type="audio"
              @change="onFilesChange(row,'audioKeys',list[index])"
            />
          </template>
          <template v-slot:video="{row,index}">
            <FileManage
              :files="row.videoKeys"
              type="video"
              @change="onFilesChange(row,'videoKeys',list[index])"
            />
          </template>
          <template v-slot:opt="{row}">
            <Icon type="md-create" class="text-primary m-r-xs" @click="editCourse(row)" />
            <Poptip confirm title="确定删除吗?" @on-ok="delCourse(row).then(requestFn)" transfer>
              <Icon class="text-error" type="md-trash" style="cursor:pointer" />
            </Poptip>
          </template>
        </Table>
      </template>
      <template #conditions="{requestFn}">
        <CategoryPanel class="flex1"
          :lastId="customedParams.cId"
          @update:lastId="customedParams.cId!==$event&&(customedParams.cId=$event,requestFn())"
          @clickadd="addCourse"
        />
        <CoursewareForm
          v-if="!!editingCourse"
          :value="!!editingCourse"
          :formData="editingCourse"
          @cancel="editingCourse=null"
          @finished="editingCourse=null,requestFn()"
        />
      </template>
    </ListFrame>
  </div>
</template>

<script>
import CategoryPanel from './components/CategoryPanel';
import CoursewareForm from './CoursewareForm';
import { getList, del, modify } from './coursewareService';
import FileManage from './FileManage';
export default {
  components: { CategoryPanel, CoursewareForm, FileManage },
  data() {
    return {
      customedParams: { cId: '' },
      editingCourse: null,
      columns: [
        {
          title: '标题',
          key: 'title',
          width: 145
        },
        {
          title: '是否上架',
          slot: 'shelf',
          width: 92
        },
        {
          title: '序号',
          key: 'sort',
          width: 80
        },
        {
          title: '上传课件',
          slot: 'courseware',
          width: 173
        },
        {
          title: '上传音频',
          slot: 'audio',
          width: 180
        },
        {
          title: '上传视频',
          slot: 'video',
          width: 174
        },
        {
          title: '操作',
          slot: 'opt',
          width: 90
        }
      ]
    };
  },
  computed: {
    roleCode() {
      return this.$store.getters.getRoleCode;
    }
  },
  methods: {
    getPageFn(params) {
      if (!params.cId) {
        return Promise.resolve({
          rows: [],
          totalCount: 0
        });
      }
      return getList(params);
    },
    addCourse(cid) {
      this.editingCourse = {
        audioKeys: [],
        cid,
        courseKeys: [],
        sort: null,
        subject: this.$store.getters.getLaun,
        title: '',
        videoKeys: []
      };
    },
    editCourse(item) {
      this.editingCourse = JSON.parse(JSON.stringify(item));
    },
    delCourse(item) {
      return del(item.id);
    },
    onFilesChange(item, key, oItem) {
      return modify(item).catch((err) => {
        item[key].splice(0, item[key].length, ...oItem[key]);
        this.$Message.warning(err);
      });
    },
    changeShelf(status, item) {
      const newStatus = status ? 1 : 2; // 上架1，下架2
      const oldStatus = item.status;
      item.status = newStatus;
      return modify(item).catch((err) => {
        item.status = oldStatus;
        this.$Message.warning(err);
      });
    }
  }
};
</script>

<style>
</style>