<!--
 * @Author: hejinsheng
 * @Date: 2022-06-16 18:24:21
 * @LastEditors: hejinsheng
 * @LastEditTime: 2022-06-17 19:13:15
 * @FilePath: \lshy-manage\src\page-courseware\components\CategoryModal.vue
-->

<template>
  <div>
    <Modal v-model="model" :loading="loading" title="版本修改" @on-ok="ok" @on-cancel="cancel">
      <Form :model="formItem" :label-width="80">
        <FormItem label="版本">
          <Input v-model="formItem.name" placeholder="请输入版本名称"></Input>
        </FormItem>
        <FormItem label="机构ID">
          <Input type="number" v-model="formItem.orgId" placeholder="请输入机构ID，不填表示all。"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>

export default {
  props: ["item", "modelShow"],
  data() {
    return {
      model: false,
      loading: true,
      formItem: {
        name: this.item ? this.item.name : "",
        orgIds: this.item ? this.item.orgId : "",
      },
    };
  },
  methods: {
    messageWarningFn(text) {
      this.$Message.warning(text)
      setTimeout(() => {
        this.loading = false
        this.$nextTick(() => {
          this.loading = true
        })
      }, 500)
    },
    ok() {
      let formItem = this.formItem
      if (formItem.name == '' || formItem.name == null) {
        this.messageWarningFn('请输入版本信息！')
        return
      }
      if (formItem.orgId != "" && formItem.orgId != null && parseInt(formItem.orgId) <= 0) {
        this.messageWarningFn('请检查机构ID！')
        return
      }
      this.$emit("ok", formItem.name.trim(), formItem.orgId);
    },
    cancel() {
      this.$emit("close");
    },
  },
  watch: {
    modelShow: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.model = newVal;
        this.formItem.name = this.item ? this.item.name : ""
        this.formItem.orgId = this.item ? this.item.orgId : ""
        console.log(this.item, this.formItem.orgId, 11111)
      },
    },
  },
};
</script>
