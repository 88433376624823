<template>
  <div class="flex-wrap mb">
    <CategoryItem v-for="item in list" :key="item.id" :allList="allList"
      :active="selectedItem && selectedItem.id === item.id" :item="item"
      :editable="lastSelectedItem && lastSelectedItem.id === item.id" @select="$emit('select', item)" @del="delItem(item)" />
    <template v-if="showAdd">
      <CategoryForm v-if="adding && code != 0" @ok="addItem" @colse="adding = false" />
      <Button v-else @click="adding = true" type="dashed" size="small">
        <Icon type="md-add" />
      </Button>

      <!-- 编辑弹出模块 -->
      <CategoryModal v-if="code == 0" :item="''" :modelShow="adding" @ok="modalOk" @close="adding = false" />

    </template>

  </div>
</template>

<script>
import CategoryForm from './CategoryForm';
import CategoryItem from './CategoryItem';
import CategoryModal from './CategoryModal';
import { addCategory, delCategory } from './categoryService';
export default {
  components: { CategoryItem, CategoryForm, CategoryModal },
  data() {
    return {
      adding: false
    };
  },
  props: {
    selectedItem: {},
    lastSelectedItem: {},
    allList: {
      required: true
    },
    code: {
      required: true
    },
    parentId: {
      required: true
    }
  },
  computed: {
    list() {
      return this.allList.filter(
        (ele) => ele.code === this.code && (this.code === 0 || ele.parentId === this.parentId)
      );
    },
    showAdd() {
      if (this.code === 0 && !this.list.length) {
        return true;
      }
      if (!this.lastSelectedItem) {
        return false;
      }
      if (this.lastSelectedItem.code === this.code || this.lastSelectedItem.code === this.code - 1) {
        return true;
      }
      return false;
    }
  },
  methods: {
    modalOk(name, orgId) {
      this.adding = false;
      if (!name || !name.trim()) {
        return;
      }
      name = name.trim();
      addCategory({
        name,
        code: this.code,
        parentId: this.parentId,
        subject: this.$store.getters.getLaun,
        orgId: orgId,
      }).then((res) => {
        this.allList.push(res);
        this.$emit('select', res);
      });
    },
    addItem(name) {
      this.adding = false;
      if (!name || !name.trim()) {
        return;
      }
      name = name.trim();
      addCategory({
        name,
        code: this.code,
        parentId: this.parentId,
        subject: this.$store.getters.getLaun
      }).then((res) => {
        this.allList.push(res);
        this.$emit('select', res);
      });
    },
    delItem(item) {
      delCategory(item.id).then((res) => {
        this.allList.splice(
          this.allList.findIndex((ele) => ele.id === item.id),
          1
        );
        this.$emit('delLast')
      }).catch(err => {
        this.$Message.warning(err)
      });
    }
  }
};
</script>

<style scoped>
.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap.mb>* {
  margin-bottom: 10px;
}
</style>