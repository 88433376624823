import axios from '@/api/newAxiosProxy'

/**
 * 
 * @param {{subject}} params 
 */
export function getList(params) {
    return axios({
        url: `/coursewareManage/courseware/list`,
        method: 'get',
        params
    }, true).then(res => {
        res = res || []
        res.forEach(ele => {
            ele.code = (config.find(e => e.codeName === ele.levelCode) || {}).code
        })
        return res;
    })
}
/**
 * 
 * @param {{name,code,parentId,subject}} data 
 */
export function addCategory(data) {
    data.levelCode = (config.find(ele => ele.code === data.code) || {}).codeName
    return axios({
        method: 'post',
        url: `/coursewareManage/courseware/add`,
        data
    }, true).then(res => {
        res.code = data.code
        return res;
    })
}

export function delCategory(id) {
    return axios({
        method: 'delete',
        url: `/coursewareManage/courseware/delete/${id}`
    })
}

export function modifyCategory(data) {
    return axios({
        method: 'put',
        url: '/coursewareManage/courseware/update',
        data
    })
}

export const config = [
    // code 仅表示层级顺序，仅前端使用，第一层必须要是0
    {
        code: 0,
        codeName: 'edition',
        name: '版本'
    }, {
        code: 1,
        codeName: 'period',
        name: '阶段'
    }, {
        code: 2,
        codeName: 'academicYear',
        name: '学年'
    }, {
        code: 3,
        codeName: 'term',
        name: '学期'
    }, {
        code: 4,
        codeName: 'theme',
        name: '主题'
    }]