
import axios from '@/api/newAxiosProxy'
export function create(data) {
    return axios({
        method: 'post',
        url: '/coursewareManage/course/add',
        data
    })
}

export function modify(data) {
    return axios({
        method: 'put',
        url: '/coursewareManage/course/update',
        data
    })
}

export function del(id) {
    return axios({
        method: 'delete',
        url: `/coursewareManage/course/delete/${id}`
    },true)
}

/**
 * 
 * @param {{pageNo,pageSize,cId}} params 
 */
export function getList(params) {
    return axios({
        method: 'get',
        url: '/coursewareManage/course/list',
        params
    }).then(res => {
        if (res && res.rows) {
            res.rows.forEach(ele => {
                ele.subject = ele.subject.toLowerCase()
                ele.audioKeys = ele.audioKeys || []
                ele.courseKeys = ele.courseKeys || []
                ele.videoKeys = ele.videoKeys || []
            })
        }
        return res
    })
}