<template>
  <div @click.stop class="form">
    <Input
      size="small"
      :value="value"
      search
      enter-button="确定"
      :placeholder="placeholder"
      @on-search="onSearch"
    />
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      default: '请输入名称'
    },
    value: {
      default: ''
    }
  },
  methods: {
    onSearch(e) {
      this.$emit('ok', e);
    }
  },
  mounted() {
    setTimeout(() => {
      const fn = () => {
        this.$emit('colse');
      };
      window.addEventListener('click', fn);
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('click', fn);
      });
    }, 100);
  }
};
</script>

<style scoped>
.form {
  max-width: 12em;
  display: inline-block;
  vertical-align: middle;
}
</style>