<template>
  <div class="flex">
    <div v-if="maxLen>1||files.length<maxLen">
      <Icon
        type="md-cloud-upload"
        class="upload text-primary"
        style="margin-right:8px"
        @click="uploadFile"
      />
    </div>
    <div class="flex1">
      <div v-for="(info,i) in files" :key="i">
        {{info.fileName}}
        <Poptip confirm transfer title="确定删除吗" @on-ok="del(i)">
          <Icon style="cursor:pointer;" class="text-error" type="md-backspace" />
        </Poptip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    files: {
      required: true
    },
    type: {
      validator: function (value) {
        return ['pdf', 'audio', 'video'].indexOf(value) !== -1;
      }
    },
    maxLen: {
      type: Number,
      default: 999
    }
  },
  methods: {
    _selectFile() {
      return new Promise((resolve) => {
        const input = document.createElement('input');
        input.type = 'file';
        let code;
        if (this.type === 'pdf') {
          input.accept = '.pdf';
          code = 4;
        } else if (this.type === 'audio') {
          input.accept = '.mp3';
          input.multiple = 'multiple';
          code = 2;
        } else if (this.type === 'video') {
          input.accept = '.mp4';
          input.multiple = 'multiple';
          code = 3;
        }
        // 是否可以多选？
        input.click();
        input.onchange = () => {
          const ofiles = Array.prototype.slice.call(input.files);
          let files = [];

          if (code === 2) {
            // 音频
            // files = ofiles.filter((file) => /^audio\/.+/i.test(file.type));
            if (ofiles.some((file) => /^audio\/.+/i.test(file.type) && !/\.mp3$/i.test(file.name))) {
              this.$Message.warning('音频只支持mp3格式！');
            }
            files = ofiles.filter((file) => /\.mp3$/i.test(file.name));
          } else if (code === 3) {
            // 视频
            // files = ofiles.filter((file) => /^video\/.+/i.test(file.type));
            if (ofiles.some((file) => /^video\/.+/i.test(file.type) && !/\.mp4$/i.test(file.name))) {
              this.$Message.warning('视频只支持mp4格式！');
            }
            files = ofiles.filter((file) => /\.mp4$/i.test(file.name));
          } else if (code === 4) {
            // pdf
            files = ofiles.filter((file) => /\.pdf$/i.test(file.name));
          }
          if (ofiles.length !== files.length) {
            this.$Message.warning('已自动舍弃非法类型的文件！');
          }
          // console.log(ofiles, files);
          resolve({ files, code });
        };
      });
    },
    async uploadFile() {
      const { files, code } = await this._selectFile();
      for (let file of files) {
        let { key, url } = await this.$uploadService.uploadOne(file);
        this.files.push({
          fileName: file.name,
          fileSize: file.size,
          key
        });
      }
      this.$emit('change', this.files);
    },
    del(ind) {
      this.files.splice(ind, 1);
      this.$emit('change', this.files);
    }
  }
};
</script>

<style scoped>
.upload {
  font-size: 20px;
  cursor: pointer;
}
</style>