<template>
  <div>
    <div v-for="(lvl,i) in lvls" :key="lvl.code" class="flex">
      <div class="tagTitle">{{lvl.name}}:</div>
      <CategoryGroup
        class="flex1 flex-middle"
        @select="selectCategory"
        @delLast="ondelLast"
        :selectedItem="selects[i]"
        :allList="allList"
        :code="lvl.code"
        :parentId="selects[i-1]&&selects[i-1].id"
        :lastSelectedItem="selects[selects.length-1]"
      />
    </div>

    <div>
      <b>已选择：</b>
      <span v-for="(sItem,i) in selects" :key="sItem.id" class="text-primary">
        <i v-if="i>0">&nbsp;/&nbsp;</i>
        {{sItem.name}}
      </span>
      <Button :disabled="selects.length!==lvls.length" class="fr" type="primary" @click="$emit('clickadd',selects[lvls.length - 1].id)">
        <Icon type="md-add" />&nbsp;新增
      </Button>
    </div>
  </div>
</template>

<script>
import CategoryGroup from './CategoryGroup';
import { getList, config } from './categoryService';
export default {
  components: { CategoryGroup },
  props: {
    lastId: {}
  },
  data() {
    return {
      lvls: config,
      allList: [],
      selects: []
    };
  },
  methods: {
    selectCategory(category) {
      this.selects.splice(category.code, this.lvls.length - category.code, category);
    },
    ondelLast() {
      this.selects.pop();
    }
  },
  mounted() {
    getList({ subject: this.$store.getters.getLaun }).then((res) => {
      this.allList = res;
      // 默认选择第一个:
      let parentId;
      for (let lvl of this.lvls) {
        let category = res.find((ele) => ele.code === lvl.code && (!parentId || parentId === ele.parentId));
        if (category) {
          parentId = category.id;
          this.selects.push(category);
        } else {
          break;
        }
      }
    });
  },
  watch: {
    selects: {
      handler() {
        if (this.selects.length === this.lvls.length) {
          this.$emit('update:lastId', this.selects[this.lvls.length - 1].id);
        } else {
          this.$emit('update:lastId');
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.tagTitle {
  font-weight: bold;
  width: 3em;
  margin-right: 0.5em;
  padding: 2px 0;
}
</style>